.content-popup-condition {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.content-popupgame {
  background-color: #ffffff;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border: 7px solid #ff9000;
}
.img-title {
  top: -5%;
  left: 0%;
  display: flex;
  justify-content: center;
}
.border-loading-game {
  border: 2px solid #4fc3f7;
}
.loading-bar {
  width: 280px;
  height: 14px;
  border-radius: 50px;
  background: #fef1f1;
  position: relative;
  overflow: hidden;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #16a5a3, #16a5a3);
  border-radius: 50px;
  animation: moveBubbles 8s forwards alternate linear;
}

@keyframes moveBubbles {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
.border-btn18 {
  border: 3px solid #fddfa8;
}
.content-physical-popup {
  top: 50%;
  transform: translateY(-50%);
}
.content-popup-condition {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.border-contact {
  border: 2px solid #4e9abb;
  border-radius: 16px;
}
.button-primary {
  background-color: #5bc2dc;
}
.containerNotify__background {
  background: #fff;
  overflow: hidden;
}
.containerNotify__background .containerNotify__background-list {
  overflow-y: scroll !important;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.bg-coundown {
  background: url(../image/Login/bg_default.png);
  background-position: center;
  background-size: cover;
}
.popupGift__background {
  background: transparent;
  overflow: hidden;
}
.popupGift__background .popupGift__background-list {
  overflow-y: scroll !important;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.bg-main {
  background-image: linear-gradient(#b6e8fd, #50b1ca);
  width: 100%;
  min-height: 40vh;
  /* max-height: 40vh; */
}
.bg-contain {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: scroll !important;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  min-height: 40vh;
  max-height: 60vh;
}
.bg-contain::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.input-hidden:focus {
  border: none;
  outline-offset: 0px;
  outline: none;
}
.popup-confirm-phone {
  background: url(../../assets/image/TakeAPhoto/header_popup_phone.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px 40px 0 0;
}
.image__phone {
  transform: translate(-50%, -50%);
  height: 80px;
  width: 80px;
}
.nav__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  animation: faceIn linear 0.2s;
}
.nav__check:checked ~ .nav__overlay {
  display: block;
  opacity: 1;
}
.containerNotify__background-tc {
  border-radius: 30px;
  background: #fff;
  overflow: hidden;
}
.containerNotify__background-tc .containerNotify__background-listtc {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.containerNotify__background-tcpopup .containerNotify__background-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
