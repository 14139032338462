.containerNotify {
  background: url(../../assets/image/ListGift/backgroundNotify.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.containerNotify__header-btnBack {
  background: url(../../assets/image/ListGift/backbtnNoti.svg);
  background-repeat: no-repeat;
}
.containerNotify__header-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.containerNotify__background {
  border-radius: 30px 30px 0 0;
  background: #fff;
  overflow: hidden;
}
.containerNotify__background .containerNotify__background-listnoti {
  overflow-y: auto;
  max-height: 95%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
@media (max-width: 376px) {
  .containerNotify__background .containerNotify__background-listnoti {
    overflow-y: auto;
    max-height: 80%;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
.containerNotify__background-itemChildName {
  font-family: Montserrat-SemiBold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.content-notify {
  font-size: 13px;
  line-height: 18px;
}

.containerNotify__background-itemChildContent {
  font-family: Montserrat-Regular;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.containerNotify__background-itemChildTime {
  font-family: Montserrat-Light;
  font-size: 12px;
  line-height: 16px;
  color: #a9a9a9;
}
.containerNotify__background-item {
  display: flex;
  padding: 5px 25px 5px 25px;
  height: 59px;
}
@media (max-width: 356px) {
  .containerNotify__background-itemChildContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .containerNotify__background-itemChildName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .containerNotify__background-itemChildTime {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.containerNotify__checkcode {
  border-radius: 30px 30px 0 0;
  background: #fff;
  overflow: hidden;
}
.containerNotify__checkcode .containerNotify__checkcode-list {
  overflow-y: auto;
  max-height: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.product-infomation {
  animation: move 0.6s ease-in-out;
}
.text-item-gift {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #828282;
  line-height: 20px;
  max-width: 100%;
}
.input-hidden-link {
  background-color: transparent;
}
.input-hidden-link:focus {
  border: none;
  outline-offset: 0px;
  outline: none;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: #333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  top: -30px;
  right: 10px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.icon-dropdown {
  background: url(../image/Icon/icon-dropdown.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-droptop {
  background: url(../image/Icon/icon-droptop.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-size {
  width: 24px;
  height: 24px;
  z-index: 50;
  margin-top: 12px;
  margin-left: 12px;
}
.background-bottom-icon {
  background: url(../image/Icon/background-bottom-list-rotation.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.icon-bottom {
  background: url(../image/Icon/icon-bottom-list-rotation.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}
.text-reponsive-date {
  font-family: Montserrat-Italic;
  font-size: 13px;
  line-height: 16px;
}
@media (max-width: 399px) {
  .icon-bottom {
    background: url(../image/Icon/icon-bottom-list-rotation.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 15px;
    margin-left: 15px;
  }
  .bgr-icon-back {
    width: 45px;
    height: 45px;
  }
  .image-product {
    width: 90px;
    height: 90px;
  }
  .text-reponsive {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.period-parhis {
  font-size: 11px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
  /* max-width: 180px; */
  text-align: center;
}

.quantity-parhis {
  font-size: 16px;
  line-height: 16px;
  font-family: Montserrat-Medium;
}
.content-description-his {
  font-size: 13px;
  line-height: 18px;
  font-family: Montserrat-SemiBold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.participatehis-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.border-table-listgift {
  /* border: 1px solid #333; */
  background-color: #bdbdbd;
  border-radius: 5px;
  padding: 5px;
  /* width: 45px; */
  text-align: right;
  height: 30px;
}
.border-table-listprize {
  border-radius: 5px;
  padding-top: 2px;
  /* width: 40px; */
  text-align: right;
  font-size: 11px;
  height: 30px;
}
.border-table-listwhitegift {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  /* width: 75%; */
  text-align: right;
  height: 30px;
}
.border-head {
  /* border-radius: 20px; */
  width: 50px;
  height: 40px;
  background-color: #16a5a3;
}
.border-head-totalgift {
  /* border-radius: 20px; */
  width: 70px;
  background-color: #16a5a3;
}
.text-red {
  color: #f30d0c;
}
.text-green {
  color: #0aaa52;
}
.text-orange {
  color: #d4961d;
}
